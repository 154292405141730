import axios, { AxiosResponse } from "axios"
import {
  sendPasswordResetEmail,
  sendSignInLinkToEmail,
  signInWithEmailAndPassword,
} from "firebase/auth"

import { NextPage } from "next"
import { useRouter } from "next/router"
import { FormEventHandler, useCallback, useEffect, useState } from "react"
import { toast } from "react-toastify"

import useFirebase from "@/lib/firebase"

import { useAuth } from "@/Context/auth.context"

import Seo from "@/components/Seo"

const Login: NextPage = () => {
  const { auth } = useFirebase()
  console.clear()
  console.log(auth)
  const [message, setMessage] = useState(
    "Please enter your Email to login or signup",
  )
  const [loading, setLoading] = useState(false)
  const [email, setEmail] = useState<string | undefined>(undefined)
  const [password, setPassword] = useState<string | undefined>(undefined)
  const [btnText, setBtnText] = useState("Submit")
  const [newEmail, setNewEmail] = useState(false)
  const [requirePassword, setRequirePassword] = useState(false)
  const { user, loading: contextLoading } = useAuth()
  const router = useRouter()
  const [resetLoading, setResetLoading] = useState(false)

  useEffect(() => {
    if (user) {
      toast.info("Login successful")
      router.push("/")
    }
  }, [user, router])

  const login = useCallback(
    async (em: string) => {
      if (!auth) {
        toast.error("Error with auth")
        return
      }
      setLoading(true)
      const host = window.location.host
      const url = host.includes("localhost")
        ? `http://${host}/login/verify`
        : `https://${host}/login/verify`
      try {
        console.clear()
        console.log(auth)
        sendSignInLinkToEmail(auth, em, {
          url,
          handleCodeInApp: true,
        }).then(() => {
          window.localStorage.setItem("email", em)
          setLoading(false)
          setEmail("")
          setMessage("Please check your email")
          setBtnText("Get Magiclink")
          setNewEmail(false)
          toast.success("Magiclink has been sent to your email.")
        })
      } catch (error) {
        toast.error(
          (error as { message?: string }).message ||
            "Something went wrong. Please try later",
        )
        setLoading(false)
        console.log(error)
      }
    },
    [auth],
  )

  const checkForMembership = useCallback(
    async (em: string) => {
      setLoading(true)
      // const { data } = (await axios.get(
      //   `/api/gymMembershipExists?email=${em}`,
      // )) as AxiosResponse<{
      //   exists: boolean
      // }>
      const { data } = (await axios.get(
        `/api/gymMembershipExists?email=${em}`,
      )) as AxiosResponse<{
        exists: boolean
        isPasswordAuthenticated: boolean
      }>

      if (!data.exists) {
        setMessage(
          "No membership found with this e-mail. Want to try another e-mail?",
        )
        setBtnText("Use this email anyway")
        setNewEmail(true)
        setLoading(false)
      } else if (data.isPasswordAuthenticated) {
        setRequirePassword(true)
        setBtnText("Login")
        setLoading(false)
      } else {
        login(em)
      }
    },
    [login],
  )

  const handleSubmit: FormEventHandler<HTMLFormElement> = async (e) => {
    e.preventDefault()
    if (!email) {
      toast.error("Email is required")
      return
    }

    if (requirePassword && !password) {
      toast.error("Password is required")
      return
    }

    if (requirePassword) {
      setLoading(true)
      try {
        signInWithEmailAndPassword(auth, email, password!)
      } catch (err: any) {
        const errorCode = err.code
        if (errorCode == "auth/email-already-in-use") {
          toast.error(err.message)
        } else if ("message" in err) {
          throw new Error(err.message.replace("Firebase: ", ""))
        } else {
          throw new Error("Failed to Sign up")
        }
      }
      setLoading(false)
      return
    }

    if (newEmail) {
      login(email)
    } else {
      checkForMembership(email)
    }
    // login(email)
  }

  return (
    <main>
      <Seo
        title="Login | Body Factory Bali "
        description="Manage your Body Factory Bali account. Sign in today.  "
      />
      <div className="my-[100px] h-[40vh] max-w-full flex items-center justify-center">
        <div className="shadow-xl border px-8 h-fit py-7 rounded-[10px]">
          <h1 className="font-display max-w-[320px] mb-[30px] text-xl">
            {message}
          </h1>
          <form data-cy="login-form" onSubmit={handleSubmit}>
            <div>
              <label sr-only className="hidden font-semibold" htmlFor="email">
                Email
              </label>
              <input
                data-cy="email"
                className="block px-3 py-2 border w-full mt-[10px] rounded-[10px] shadow-md border-gray-300"
                id="email"
                type="email"
                required
                placeholder="E-mail"
                value={email}
                onChange={(e) => {
                  setNewEmail(false)
                  setEmail(e.target.value)
                }}
              />
            </div>
            {requirePassword && (
              <div>
                <div>
                  <label
                    sr-only
                    className="hidden font-semibold"
                    htmlFor="password"
                  >
                    Password
                  </label>
                  <input
                    data-cy="password"
                    className="block px-3 py-2 border w-full mt-[10px] rounded-[10px] shadow-md border-gray-300"
                    id="password"
                    type="password"
                    required
                    placeholder="Password"
                    value={password}
                    onChange={(e) => {
                      setPassword(e.target.value)
                    }}
                  />
                </div>
                <div className="mt-4 text-right">
                  <button
                    aria-label="Forgot password"
                    disabled={resetLoading}
                    onClick={async () => {
                      if (!email) return
                      setResetLoading(true)

                      await sendPasswordResetEmail(auth, email, {
                        url: window.location.href,
                        handleCodeInApp: true,
                      })
                      toast.success("Password reset link has been sent")
                      setResetLoading(false)
                    }}
                    className="font-medium text-blue-400 disabled:pointer-events-none disabled:opacity-30 hover:underline focus:underline hover:text-blue-500 focus:text-blue-500"
                  >
                    Forgot password?
                  </button>
                </div>
              </div>
            )}
            <button
              aria-label="Login"
              data-cy="login-button"
              type="submit"
              disabled={loading || contextLoading || !auth}
              className="bg-brand mt-[20px] w-full hover:bg-text hover:text-brand transition-colors text-black duration-150 ease-out rounded-[8px] font-bold text-md -skew-x-[21deg] disabled:pointer-events-none disabled:opacity-40 inline-block pt-[20px]  pb-[16px] pl-[63px]  group pr-[53px] max-w-[320px]"
            >
              <div className="skew-x-[21deg] font-display flex justify-center uppercase items-center gap-[14px]">
                {btnText}
              </div>
            </button>
          </form>
        </div>
      </div>
    </main>
  )
}
export default Login

// import { IUser, useAuth } from "@/Context/auth.context"
// import { auth } from "@/lib/firebase"
// import axios, { AxiosResponse } from "axios"
// import { sendSignInLinkToEmail } from "firebase/auth"
// import { NextPage } from "next"
// import { useRouter } from "next/router"
// import { FormEventHandler, useCallback, useRef, useState } from "react"
// import { toast } from "react-toastify"

// const Login: NextPage = () => {
//   const inputRef = useRef<HTMLInputElement>(null)
//   const [message, setMessage] = useState("Please enter your Email to login")
//   const [loading, setLoading] = useState(false)
//   const [email, setEmail] = useState<string | undefined>(undefined)
//   const [password, setPassword] = useState<string | undefined>(undefined)
//   const { login } = useAuth()
//   const router = useRouter()

//   const handleSubmit: FormEventHandler<HTMLFormElement> = async (e) => {
//     e.preventDefault()
//     if (!email || !password) {
//       toast.error("Email and password are required")
//       return
//     }
//     setLoading(true)
//     await login(email, password)
//     setLoading(false)
//     toast.success("Logged in successfully")
//     router.push("/")
//   }
//   return (
//     <main>
//       <div className="my-[100px] h-[40vh] max-w-full flex items-center justify-center">
//         <div className="shadow-xl border px-8 h-fit py-7 rounded-[10px]">
//           <h1 className="font-display max-w-[320px] mb-[30px] text-xl">
//             Enter your email and password
//           </h1>
//           <form onSubmit={handleSubmit}>
//             <div>
//               <label sr-only className="hidden font-semibold" htmlFor="email">
//                 Email
//               </label>
//               <input
//                 className="block px-3 py-2 border w-full mt-[10px] rounded-[10px] shadow-md border-gray-300"
//                 id="email"
//                 type="email"
//                 required
//                 placeholder="E-mail"
//                 value={email}
//                 onChange={(e) => {
//                   // setNewEmail(false)
//                   setEmail(e.target.value)
//                 }}
//               />
//             </div>
//             <div>
//               <label
//                 sr-only
//                 className="hidden font-semibold"
//                 htmlFor="password"
//               >
//                 Password
//               </label>
//               <input
//                 className="block px-3 py-2 border w-full mt-[10px] rounded-[10px] shadow-md border-gray-300"
//                 id="password"
//                 type="password"
//                 required
//                 placeholder="Password"
//                 value={password}
//                 onChange={(e) => {
//                   // setNewEmail(false)
//                   setPassword(e.target.value)
//                 }}
//               />
//             </div>
//             <div className="mt-[20px]">
//               <button className="font-medium text-blue-400 hover:underline focus:underline hover:text-blue-500 focus:text-blue-500">
//                 Forgot password?
//               </button>
//             </div>
//             <button
//               type="submit"
//               disabled={loading}
//               className="bg-brand mt-[20px] w-full hover:bg-text hover:text-brand transition-colors text-black duration-150 ease-out rounded-[8px] font-bold text-md -skew-x-[21deg] disabled:pointer-events-none disabled:opacity-40 inline-block pt-[20px]  pb-[16px] pl-[63px]  group pr-[53px] max-w-[320px]"
//             >
//               <div className="skew-x-[21deg] font-display flex justify-center uppercase items-center gap-[14px]">
//                 Login
//               </div>
//             </button>
//           </form>
//         </div>
//       </div>
//     </main>
//   )
// }

// export default Login
